import { useTitle } from 'react-use';
import Fade from 'react-reveal/Fade';
import Container from 'react-bootstrap/Container';
import Title from '../components/Title/Title';
import Subtitle from '../components/Subtitle/Subtitle';
import Text from '../components/Text/Text';
import ContentCard from '../components/ContentCard/ContentCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { metropolitans, msca_diocese } from '../data/OfficeBearers';
import { officebearers } from '../data/OfficeBearers';
import { msca } from '../data/OfficeBearers';

const OfficeBearers = () => {
    useTitle('SMIOC - Office Bearers');
    return (
        <Container>
            <Fade>
                <Title title="Office Bearers" />
                <Text description="Catholicos of the East and Malankara Metropolitan are the titles used by 
                the Malankara Orthodox Syrian Church, which is also called the Indian Orthodox Church, for 
                the same bishop holding two offices of Catholicos of the East and Malankara Metropolitan. 
                The Diocese of UK, Europe and Africa is one of the 30 dioceses of the Malankara Orthodox Syrian 
                Church with its headquarters in London." />
            </Fade>

            
        
            <Fade>
            <Title title="Spiritual Leaders" />
                <Row className="justify-content-md-center">
                    {metropolitans.map((member) => (
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <ContentCard title={member.title} 
                                         name={member.name} 
                                         href={member.href} 
                                         image={member.image} 
                                         cardtype="office" /><br />
                        </Col>
                    ))}
                </Row>
            </Fade>


            <Fade>
                <Title title="Managing Committee" />
                <Subtitle className={"subtitle"} subtitle="April 2024 - March 2025" />
                <Text description="The members of the Parish Managing Committee excluding the priests will be elected by the 
                Parish Assembly and their term of office will be one year. Every Parish Managing Committee will have a minimum 
                of 5 and a maximum of 15 members excluding the Priests." />
            </Fade>

            <Row className="justify-content-md-center">
                
                {officebearers.map((member) => (
                    <Col lg={4} md={6} sm={12} xs={12}>
                        <ContentCard title={member.title} 
                                     name={member.name} 
                                     image={member.image} 
                                     cardtype="office" /><br />
                    </Col>
                ))}

            </Row>

            <Fade>
                <Title title="Malankara Syrian Christian Association" />
                <Subtitle className={"subtitle"} subtitle="2022 - 2027" />

                <Text description="It was in the Mulamthuruthy synod summoned by the Patriarch Peter III in 1876 that resolved to 
                have an elected body called the Malankara Syria Christian Association to manage and control all the religions and 
                social concerns of the whole church." />
            </Fade>


            <Fade>
                <Row className="justify-content-md-center">
                    {msca.map((member) => (
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <ContentCard title={member.title}
                                         name={member.name} 
                                         href={member.href}
                                         image={member.image} 
                                         cardtype="office" /><br />
                        </Col>
                    ))}
                </Row>

                <br /><Subtitle className={"subtitle"} subtitle="2023 - 2028" />

                <Row className="justify-content-md-center">
                    {msca_diocese.map((member) => (
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <ContentCard title={member.title}
                                         name={member.name} 
                                         href={member.href}
                                         image={member.image} 
                                         cardtype="office" /><br />
                        </Col>
                    ))}
                </Row>
            </Fade>
            <br /><br />
        </Container>


    )
}

export default OfficeBearers;
