import Container from 'react-bootstrap/Container';
import { useTitle } from 'react-use';
import Title from '../components/Title/Title';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { photos } from '../data/Photos.js';
import { videos } from '../data/Videos.js'




import Slideshow from '../components/Slideshow/Slideshow';

const Gallery = () => {
    useTitle('SMIOC - Gallery');

    return (
        
        <Container>
            <Title title="GALLERY" />

                {/* <Tabs className="gallery-tabs mb-3" fill variant='tabs'> */}
                    {/* <Tab eventKey="photos" title="Photos"> */}
                        <Slideshow photos={photos} />
                    {/* </Tab> */}
                    {/* <Tab eventKey="video" title="Videos">
                        <Slideshow videos={videos} />
                    </Tab> */}
                {/* </Tabs>  */}

            <br /><br />
        </Container>
    )
}

export default Gallery;