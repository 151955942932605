import { Container } from 'react-bootstrap';
import '../Subtitle/subtitle.css'

const Subtitle = ({ className, subtitle }) => {
    return (
        <Container>
            <h3 className={className}>{subtitle}</h3>
        </Container>
        
    )
}

export default Subtitle;
