import Container from 'react-bootstrap/Container';
import '../Note/note.css'
import Text from '../Text/Text';
import Fade from 'react-reveal/Fade';

const Note = ( props) => {
    return (
        <Fade big cascade>
            <Container className="note">
                <Text className="description" description={props.description} />
                <Text className="verse" description={props.verse} />
            </Container>
        </Fade>
    )
} 
export default Note;