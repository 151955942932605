import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import '../Prefooter/prefooter.css'
import { ImLocation2 } from 'react-icons/im';
import { FaChurch } from 'react-icons/fa';
import { FaFacebook } from "react-icons/fa";
import SMIOC from '../../assets/logos/smioc-logo.png';
import MOSC from '../../assets/logos/mosc.png';
import { MdEmail } from 'react-icons/md';

const Prefooter = () => {
    return (
        <Container fluid className="prefooter">
            <Row className="align-items-center">
                <Col>
                    <img src={SMIOC} alt="SMIOC Logo v2" className="logo"/>
                </Col>
                <Col md={4} xs={12} className="info">
                    <Row><ImLocation2 size="2rem" color="#D9261C"/></Row>
                    Worshipping at: <br />
                    St. Cecilia R.C. Church <br />Ryhope Road, Sunderland<br />SR2 7TG
                    <br /><br />
                    <Row><FaChurch size="2rem" color="#D9261C"/></Row>
                    <a href="https://register-of-charities.charitycommission.gov.uk/charity-search/-/charity-details/5212773/charity-overview">Registered Charity Number: 1204560</a>
                    <br />
                    <Row><MdEmail size="2rem" color="#D9261C"/></Row>
                    <a href="mailto:secretary@stmarysiocsunderland.org">secretary@stmarysiocsunderland.org</a>
                    <a href="mailto:vicar@stmarysiocsunderland.org">vicar@stmarysiocsunderland.org</a>
                    <br />
                    <Row><FaFacebook size="2rem" color="#D9261C"/></Row>
                    <a href="https://www.facebook.com/profile.php?id=61551868213299">St. Mary's Indian Orthodox Church, Sunderland</a>
                </Col>
                <Col>
                <a href="https://mosc.in">
                    <img src={MOSC} alt="MOSC Logo" className="logo"/>
                </a>
                </Col>
            </Row>
            
        </Container>
    )
}

export default Prefooter;