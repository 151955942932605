import Card from 'react-bootstrap/Card';
import '../ContentCard/contentcard.css';
import Image from 'react-bootstrap/Image';
import Text from '../Text/Text';

const ContentCard = ( props ) => {
    return (
        <Card>       
            <Card.Body>
                <Card.Title className="cardtitle">{props.title}</Card.Title>
                <Card.Text className="cardname">{props.name}</Card.Text>

                {props.image ? 
                    <a href={props.href}><Image className={props.cardtype} src={props.image} /></a>
                    : ""}

                <Text description={props.description} />
            </Card.Body>
        </Card>
    )
}

export default ContentCard;