import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import '../Popup/popup.css';

const Popup = ( props ) => {
    const statusCode = props.statusCode;
    if (statusCode === 200) {
        return (
            <Modal show={props.show} onHide={props.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="modalheader" closeButton>
                    <Modal.Title className="modaltitle">
                        <BsFillCheckCircleFill size="1.5rem" color="#48C384"/>
                        &emsp;{props.title}
                        </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalbody">{props.body}</Modal.Body>
                <Modal.Footer>
                    <Button bsPrefix="close" onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    } 
}

export default Popup;