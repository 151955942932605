import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import '../TimelineCard/timelinecard.css'


const TimelineCard = ({ title, date, time, description, location }) => {
    return (

        <Container>
                <Card>       
                    <Card.Body>
                        <Card.Title className="timelinetitle">{title}</Card.Title>
                        {time ? 
                            <Card.Text className="timelinedate">{date} - {time}</Card.Text>
                            : 
                            <Card.Text className="timelinedate">{date}</Card.Text>
                        }
                        <p className="timelinedescription">{description}</p>
                        <p className="timelinelocation">{location}</p>
                    </Card.Body>
                </Card>
        </Container>


        
    )
}

export default TimelineCard;