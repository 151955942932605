import Container from 'react-bootstrap/Container';
import { useTitle } from 'react-use';
import Title from '../components/Title/Title';
import Text from '../components/Text/Text';
import Fade from 'react-reveal/Fade';

import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import I1 from '../assets/media/aboutus/1.png';


const AboutUs = () => {
    useTitle('SMIOC - About Us');
    return (
        <Container>
            <Fade>
                <Title title="About Us" />
            </Fade>


            <Fade>
                <Row className="justify-content-md-center">
                    <Text description="In the humble beginning, the Sunderland congregation had its first Indian Orthodox 
                    spiritual gathering at the Sunderland Royal Hospital Chapel on July 8 2004. As a sign of the readiness 
                    to share the Christian fraternity, the  Sunderland Catholic community and the vicar permitted us to use 
                    the chapel for our spiritual needs and worship. The first Holy Qurbana was conducted by Fr Happy Jacob 
                    at St. Joseph RC Church in Sunderland on September 4, 2004, with the permission and blessing of Late 
                    Lamented H. G. Dr. Thomas Mar Makarios, the Diocesan Bishop of the UK, Europe and Africa." />
                    
                    <Text description="At a members' meeting on January 22, 2005, the congregation accepted St. Thomas as 
                    the patron saint. Then, on July 21, 2007, we received our official declaration as a congregation from 
                    the Diocesan Bishop. On January 5, 2008, our Diocesan Metropolitan  H. G. Dr. Thomas Mar Makarios, 
                    officially acknowledged us as a parish of Malankara (Indian) Orthodox Church and appointed Rev. Fr. Happy Jacob 
                    as the vicar." />

                    <Text description="The church has a history of steadfast growth, starting with eight families and currently 
                    catering for the spiritual needs of forty-two families residing in the North East of England. Many non-members 
                    also visit this church on worship days to participate and experience the joy of spiritual unity. Due to the vast 
                    geographical sphere where new students and families are migrating, this church would be a place for them to settle 
                    and grow in the new environment." />

                    <Text description="In October 2016, St Thomas Church moved to its premises in Blaydon. From November 2016, the families 
                    from Sunderland and neighbouring areas resumed as St Mary's Indian Orthodox Prayer Group in Sunderland. The prayer group 
                    continued to conduct monthly prayers, Sunday school and MMS activities. With the approval of the Diocesan Bishop, 
                    H. G. Dr. Mathews Mar Thimothios, St Marys prayer group conducted Holy Qurbana every month from January 21, 2018. 
                    On December 1, 2021, the Diocesean Bishop, H. G. Dr. Mathews Mar Thimothios declared St Mary's prayer group as St Mary's 
                    Indian Orthodox Congregation. As of March 25, 2023, the Diocesean Bishop, H. G. Abraham Mar Stephanos declared St Mary's 
                    Indian Orthodox Congregation as St Mary's Indian Orthodox Parish." />
                </Row>
            </Fade>
            <br />
            <Fade>
                <Row className="justify-content-md-center">
                    <Image className='aboutus' src={I1} />
                </Row>
            </Fade>
            <br /><br />
        </Container>

    )
}

export default AboutUs;