import { Container } from 'react-bootstrap';
import '../Slideshow/slideshow.css';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
// import { LazyLoadImage } from 'react-lazy-load-image-component';


const Slideshow = ({ photos, videos }) => {
    return (
        <Container>
                    {photos ? 
                        <Carousel className="carousel" interval={null}>
                            {photos.map(photo => (
                                <Carousel.Item>
                                    <Image className="d-block w-100" src={photo.src} rounded/>
                                    {/* <LazyLoadImage className="d-block w-100" src={photo.image} /> */}
                                    <Carousel.Caption>
                                        <h3 className="slideshow-caption">{photo.caption}</h3>
                                        <h4 className="slideshow-description">{photo.year}</h4>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel>                    
                    :  ""
                    }

                    {videos ?
                        <Carousel className="carousel">
                            {videos.map(video => (
                                <Carousel.Item>
                                    <video controls width="100%">
                                        <source src={video.src} type="video/mp4" />
                                    </video>
                                    <Carousel.Caption>
                                        <h3 className="slideshow-caption">{video.caption}</h3>
                                        <h4 className="slideshow-description">{video.year}</h4>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))}
                        </Carousel> 
                    : ""
                    }


            

           
            

        </Container>
    )
}

export default Slideshow;