import { Link } from 'react-router-dom'
import { useTitle } from 'react-use';

const PageNotFound = () => {
    useTitle('SMIOC - Page Not Found');

    return (
        <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="text-center">
                <h1 class="display-1 fw-bold">404</h1>
                <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
                <p class="lead">
                    The page you’re looking for does not exist. Please go back to the homepage.
                </p>
                <Link className="btn btn-primary" to={"/"}>
                    Go Home
                </Link>
            </div>
        </div>

    )
}

export default PageNotFound;