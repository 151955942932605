export const events = [
    {
        "title": "Feast of Ascension",
        "date": "2024-05-05",
        "time": "6pm",
        "description": "Swargarohana Perunnal. Evening Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Feast of Pentecost",
        "date": "2024-05-19",
        "time": "9am",
        "description": "Morning Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Apostolic Visit",
        "date": "2024-05-27",
        "time": "6:30pm",
        "description": <>Join us for Evening Prayer led by our spiritual leaders.<br />H.H. Baselios Marthoma Mathews III - Catholicos of the East and Malankara Metropolitan<br />H. G. Abraham Mar Stephanos - Diocesan Metropolitan of UK, Europe and Africa<br />Bishop Stephen Wright - Bishop of Hexham and Newcastle<br /></>, 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-06-02",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-06-16",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Zonal Level Talent Day",
        "date": "2024-06-29",
        "time": "TBC",
        "description": "", 
        "location": <>St George IOC<br />15 Mortomley Lane, High Green<br />Sheffield<br />S35 3HS</>
    },
    {
        "title": "Holy Qurbana, Sports Day and Food Festival",
        "date": "2024-07-07",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-07-21",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-08-04",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Feast of the Transfiguration",
        "date": "2024-08-05",
        "time": "6pm",
        "description": "Koodara Perunal. Evening Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Feast of the Assumption of St. Mary",
        "date": "2024-08-14",
        "time": "6pm",
        "description": "Shoonoyo Perunal. Evening Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-08-18",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "OVBS Day 1",
        "date": "2024-08-30",
        "time": "9am",
        "description": "OVBS Theme: Let’s pray - St.Luke-11:1", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "OVBS Day 2",
        "date": "2024-08-31",
        "time": "9am",
        "description": "OVBS Theme: Let’s pray - St.Luke-11:1",
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana & OVBS Final Day",
        "date": "2024-09-01",
        "time": "9am",
        "description": "OVBS Theme: Let’s pray - St.Luke-11:1", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana & Onam Celebrations",
        "date": "2024-09-15",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Diocese Level Talent Day",
        "date": "2024-09-21",
        "time": "9am",
        "description": "", 
        "location": <>St. George IOC<br />South Belgrave Street, Halliwell<br />Bolton<br />BL1 3RF</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-10-06",
        "time": "9am",
        "description": "Half Yearly General Body Meeting", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana & Harvest Festival",
        "date": "2024-10-20",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "St. Gregorios Day & Harvest Festival",
        "date": "2024-11-03",
        "time": "9am",
        "description": "Parumala Perunal. Morning Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana & MMVS Retreat",
        "date": "2024-11-17",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-12-01",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-12-15",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Christmas Service",
        "date": "2024-12-24",
        "time": "TBC",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2024-12-31",
        "time": "TBC",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana & Sunday School Annual Day",
        "date": "2025-01-05",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },{
        "title": "Holy Qurbana",
        "date": "2025-01-19",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-02-02",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Fast of Nineveh",
        "date": "2025-02-12",
        "time": "TBC",
        "description": "Evening Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-02-16",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-03-02",
        "time": "9am",
        "description": "Annual General Body Meeting", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-03-16",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Feast of Annunciation to St. Mary",
        "date": "2025-03-24",
        "time": "TBC",
        "description": "Vachanipu Perunal. Evening Prayer followed by Holy Qurbana", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },




    {
        "title": "Holy Qurbana",
        "date": "2025-04-06",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-04-20",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-05-04",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-05-18",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-06-01",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-06-15",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-07-06",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-07-20",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-08-03",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-08-17",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-09-07",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-09-21",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-10-05",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-10-19",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-11-02",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-11-16",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-12-07",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },
    {
        "title": "Holy Qurbana",
        "date": "2025-12-21",
        "time": "9am",
        "description": "", 
        "location": <>St. Cecilia R.C. Church <br />Ryhope Road<br /> Sunderland<br />SR2 7TG</>
    },

  

    

]