import { Container, Nav, Navbar } from 'react-bootstrap';
import Logo from '../../assets/logos/smioc-logo-small-v2.png';
import '../Navigation/navigation.css'

const Navigation = () => {
    return (
        <Navbar fixed="top" collapseOnSelect expand='lg'>
            <Container>
                <Navbar.Brand href='/'>
                    <img alt='SMIOC Logo v2' src={Logo} height='50'/>SMIOC Sunderland
                </Navbar.Brand>

                <Navbar.Toggle aria-controls='responsive-navbar-nav' />

                <Navbar.Collapse id='responsive-navbar-nav'>
                    <Nav className='ms-auto'>
                        <Nav.Link href='aboutus'>ABOUT US</Nav.Link>
                        <Nav.Link href='gallery'>GALLERY</Nav.Link>
                        <Nav.Link href='events'>EVENTS</Nav.Link>
                        <Nav.Link href='officebearers'>OFFICE BEARERS</Nav.Link>
                        <Nav.Link href='contactus'>CONTACT US</Nav.Link>
                        <Nav.Link hidden href='404'>404</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
} 

export default Navigation;
