import { useTitle } from 'react-use';
import Container from 'react-bootstrap/Container';
import Subtitle from '../components/Subtitle/Subtitle';
import Title from '../components/Title/Title';
import Text from '../components/Text/Text';
import TimelineCard from '../components/TimelineCard/TimelineCard';
import Fade from 'react-reveal/Fade';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

import { events } from '../data/Events';

const Events = () => {
    useTitle('SMIOC - Events');
    
    return (
        <Container>
            <Fade>
                <Title title="Events" />
                <Subtitle className={"subtitle"} subtitle="2024 - 2025" />
            </Fade>
            <Fade>
                <Text className="center" description="We invite you to join our events planned for this annual year." />
            </Fade>
            <Fade>
            <Row>
                    {events.map((event) => {
                        if (moment() < moment(event.date) || moment().format("L") === moment(event.date).format("L")) {
                            return <>
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        <TimelineCard title={event.title}
                                            date={moment(event.date).format("dddd, Do MMMM YYYY")}
                                            time={event.time} 
                                            description={event.description} 
                                            location={event.location}
                                        />
                                    <br/>
                                    </Col>
                                </>;
                        }
                        return <></>
                    })}
                </Row>
            </Fade>
            <br /><br />
        </Container>

    )
}

export default Events;