import { useTitle } from 'react-use';
import Text from '../components/Text/Text';
import Subtitle from '../components/Subtitle/Subtitle';
import Title from '../components/Title/Title';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
    useTitle('SMIOC - Privacy Policy');

    return (
        <Container>
            <Title title="Privacy Policy" />

            <Text description="At SMIOC Sunderland, one of our main priorities is the privacy of our visitors. 
            The terms outlined below contains types of information that is collected from you and recorded 
            by St. Mary's Indian Orthodox Church, Sunderland and how we use it. By using our website, you 
            hereby consent to our Privacy policy and agree to its terms." />

            <Subtitle className={"subtitle-left"} subtitle="Personal Data" />

            <Text description="Personal data is information that relates to an identified or identifiable 
            individual. On our website, we collect your contact details: name, email, mobile number and 
            any information your provide in the message. By collecting such information, this allows SMIOC 
            Sunderland to contact you directly to provide you with more information regarding your 
            enquiry. We will keep your personal data for as long as necessary in order to acheive the 
            processing purposes." />

            <Subtitle className={"subtitle-left"} subtitle="Your rights in relation to your personal data" />

            <Text description="You may have the following rights in relation to your personal data 
            according to the GDPR Data Protection rights:" />

            <ul>
           
                <li>
                    The right to be informed about the personal data we collect and how your personal data is used.
                </li>
                <li>
                    The right to access – You have the right to request copies of your personal data.
                </li>
                
                <li>
                    The right to rectification – You have the right to request that we correct any information you believe is inaccurate. 
                </li>
                <li>
                    The right to erasure – You have the right to request that we erase your personal data.
                </li>
                <li>
                    The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
                </li>
                <li>
                    The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
                </li>
                <li>
                    The right to data portability – You have the right to request that we transfer the data that we have collected to another organisation, or directly to you, under certain conditions.
                </li>
            </ul>

            <Subtitle className={"subtitle-left"} subtitle="Third Party Privacy Policies" />

            <Text description="This website contains links to other websites. Please remember that we 
            are not responsible for the privacy practices of websites external to SMIOC Sunderland nor 
            the accuracy of any of the information made available on those websites. This privacy policy 
            applies only to information collected on this website." />

            <Subtitle className={"subtitle-left"} subtitle="Cookies" />

            <Text description="Like any other website, SMIOC Sunderland uses 'cookies'. These cookies 
            are used to store information including the pages on the website that you have accessed or 
            visited. The information is used to optimise the users' experience by customising our web 
            page content based on visitors' browser type and/or other information." />

            <Text description={<>This website uses Google Maps to provide the exact location of the church 
            we worship at. Google may set cookies when you use the map. The Privacy Policy for Google can 
            be found <a href="https://policies.google.com/privacy?hl=en-UK">here.</a></>} />

            <Text description="Additionally, note that St. Mary's Indian Orthodox Church, Sunderland, 
            UK has no access to or control over cookies that are used by external links provided on our 
            website." />

            <Text description="You can change the security settings of your web browser in order to stop 
            your device receiving and storing cookies, to allow receiving and storing cookies from
            selected websites only, or to be notified before receiving cookies. You may delete cookies 
            stored in your browser at any time. Information stored in such cookies will be removed from 
            your device." />

            <Subtitle className="subtitle-left" subtitle={"Contact Us"} />

            <Text description={<>If you have additional questions or concerns you would like to raise 
            about our Terms and Conditions, please do not hesitate to contact us. This 
            should be addressed directly to <a href = "mailto:secretary@stmarysiocsunderland.org">secretary@stmarysiocsunderland.org</a></>} />

            <br />


    
        </Container>
        

    )
}

export default PrivacyPolicy;