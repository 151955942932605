
import SupremeThirumeni from '../assets/media/officebearers/supremethirumeni.png';
import Thirumeni from '../assets/media/officebearers/thirumeni.jpg';
import VarghesePa from '../assets/media/officebearers/varghesepa.png';
import BaijuThomas from '../assets/media/officebearers/baijuthomas.png';
import VarghesePa2 from '../assets/media/officebearers/varghesepa2.png';
import HappyJacob from '../assets/media/officebearers/happyjacob.png';
import MerinEldhose from '../assets/media/officebearers/merineldhose.png';
import SabuKadavanthanam from '../assets/media/officebearers/sabukadavanthanam.png';
import SaraSabu from '../assets/media/officebearers/sarasabu.png';
import MohanGeorge from '../assets/media/officebearers/mohangeorge.png';
import KerlinBiji from '../assets/media/officebearers/kerlinbiji.png';
import BijiVarughese from '../assets/media/officebearers/bijivarughese.png';
import KunjumoleJoy from '../assets/media/officebearers/kunjumolejoy.png';
import JinuGeorge from '../assets/media/officebearers/jinugeorge.png';
import RajiThomas from '../assets/media/officebearers/rajithomas.png';
import JerinJoji from '../assets/media/officebearers/jerinjoji.png';
// import PlaceHolder from '../assets/media/officebearers/placeholder.png';


export const metropolitans = [
    {
        "title": <>Catholicos of East &<br /> Malankara Metropolitan</>,
        "name": "H.H. Baselios Marthoma Mathews III",
        "href": "https://mosc.in/holysynod/his-holiness-baselios-marthoma-mathews-iii", 
        "image": SupremeThirumeni
    },
    {
        "title": <>Diocesan Metropolitan of <br />UK, Europe & Africa</>,
        "name": "H. G. Abraham Mar Stephanos",
        "href": "https://mosc.in/holysynod/h-g-abraham-mar-stephanos-metropolitan", 
        "image": Thirumeni
    },
]

export const officebearers = [
    {
        "title": "Vicar",
        "name": "Rev. Fr. Varghese P. A.",
        "image": VarghesePa2
    },
    {
        "title": "Trustee",
        "name": "Kunjumole Joy",
        "image": KunjumoleJoy
    },
    {
        "title": "Secretary",
        "name": "Dr. Mohan George",
        "image": MohanGeorge
    },
    {
        "title": "MMVS Secretary",
        "name": "Merin Eldhose",
        "image": MerinEldhose
    },
    {
        "title": "Sunday School Head Teacher",
        "name": "Sara Sabu",
        "image": SaraSabu
    },
    {
        "title": "Youth Coordinator",
        "name": "Kerlin Biji",
        "image": KerlinBiji
    },
    {
        "title": "Committee Member",
        "name": "Jinu George",
        "image": JinuGeorge
    },
    {
        "title": "Committee Member",
        "name": "Raji Thomas",
        "image": RajiThomas
    },
    {
        "title": "Ex-Officio",
        "name": "Biji Varughese",
        "image": BijiVarughese
    },
    {
        "title": "Ex-Officio",
        "name": "Jerin Joji",
        "image": JerinJoji
    },
    {
        "title": "Internal Auditor",
        "name": "Baiju Thomas",
        "image": BaijuThomas
    },

];

export const msca = [
    {
        "title": "MSCA Representative",
        "name": "Rev. Fr. Happy Jacob",
        "href": "https://mosc.in/administration/malankara-association",
        "image": HappyJacob
    },
    {
        "title": "MSCA Representative",
        "name": "Sabu Kadavanthanam",
        "href": "https://mosc.in/administration/malankara-association",
        "image": SabuKadavanthanam
    },

];

export const msca_diocese = [
    {
        "title": "Diocese of UK, Europe & Africa Representative",
        "name": "Dr. Mohan George",
        "href": "https://mosc.in/administration/malankara-association",
        "image": MohanGeorge
    },

];