import P1 from '../assets/media/gallery/photos/1.jpeg';
import P5 from '../assets/media/gallery/photos/5.jpeg';
import P6 from '../assets/media/gallery/photos/6.jpeg';
import P7 from '../assets/media/gallery/photos/7.jpeg';
import P8 from '../assets/media/gallery/photos/8.jpeg';
import P9 from '../assets/media/gallery/photos/9.jpeg';
import P12 from '../assets/media/gallery/photos/12.jpeg';
import P13 from '../assets/media/gallery/photos/13.jpeg';
import P14 from '../assets/media/gallery/photos/14.jpeg';
import P15 from '../assets/media/gallery/photos/15.jpeg';
import P17 from '../assets/media/gallery/photos/17.jpeg';
import P18 from '../assets/media/gallery/photos/18.jpeg';
import P19 from '../assets/media/gallery/photos/19.jpeg';
import P20 from '../assets/media/gallery/photos/20.jpeg';
import P21 from '../assets/media/gallery/photos/21.jpeg';
import P22 from '../assets/media/gallery/photos/22.jpeg';
import P23 from '../assets/media/gallery/photos/23.jpeg';
import P24 from '../assets/media/gallery/photos/24.jpeg';
import P25 from '../assets/media/gallery/photos/25.jpeg';
import P27 from '../assets/media/gallery/photos/27.jpeg';
import P28 from '../assets/media/gallery/photos/28.jpeg';
import P29 from '../assets/media/gallery/photos/29.jpeg';
import P30 from '../assets/media/gallery/photos/30.jpeg';
import P31 from '../assets/media/gallery/photos/31.jpeg';
import P32 from '../assets/media/gallery/photos/32.jpeg';
import P33 from '../assets/media/gallery/photos/33.jpeg';
import P34 from '../assets/media/gallery/photos/34.jpeg';
import P35 from '../assets/media/gallery/photos/35.jpeg';
import P36 from '../assets/media/gallery/photos/36.jpeg';
import P37 from '../assets/media/gallery/photos/37.jpeg';
import P38 from '../assets/media/gallery/photos/38.jpeg';
import P39 from '../assets/media/gallery/photos/39.jpeg';
import P40 from '../assets/media/gallery/photos/40.jpeg';
import P41 from '../assets/media/gallery/photos/41.jpeg';
import P42 from '../assets/media/gallery/photos/42.jpeg';
import P43 from '../assets/media/gallery/photos/43.jpeg';
import P44 from '../assets/media/gallery/photos/44.png';
import P45 from '../assets/media/gallery/photos/45.png';
import P46 from '../assets/media/gallery/photos/46.png';
import P47 from '../assets/media/gallery/photos/47.png';
import P48 from '../assets/media/gallery/photos/48.png';
import P50 from '../assets/media/gallery/photos/50.png';
import P51 from '../assets/media/gallery/photos/51.png';
import P52 from '../assets/media/gallery/photos/52.png';
import P53 from '../assets/media/gallery/photos/53.png';
import P54 from '../assets/media/gallery/photos/54.png';
import P55 from '../assets/media/gallery/photos/55.png';
import P56 from '../assets/media/gallery/photos/56.png';
import P57 from '../assets/media/gallery/photos/57.png';
import P58 from '../assets/media/gallery/photos/58.jpg';
import P59 from '../assets/media/gallery/photos/59.png';
import P60 from '../assets/media/gallery/photos/60.png';
import P61 from '../assets/media/gallery/photos/61.png';
import P62 from '../assets/media/gallery/photos/62.png';
import P63 from '../assets/media/gallery/photos/63.png';
import P64 from '../assets/media/gallery/photos/64.png';
import P65 from '../assets/media/gallery/photos/65.png';
import P66 from '../assets/media/gallery/photos/66.png';
import P67 from '../assets/media/gallery/photos/67.png';
import P68 from '../assets/media/gallery/photos/68.png';
import P69 from '../assets/media/gallery/photos/69.png';
import P70 from '../assets/media/gallery/photos/70.png';
import P71 from '../assets/media/gallery/photos/71.png';
import P72 from '../assets/media/gallery/photos/72.png';
import P74 from '../assets/media/gallery/photos/74.png';
import P75 from '../assets/media/gallery/photos/75.png';
import P76 from '../assets/media/gallery/photos/76.png';
import P77 from '../assets/media/gallery/photos/77.png';
import P78 from '../assets/media/gallery/photos/78.png';
import P79 from '../assets/media/gallery/photos/79.png';
import P80 from '../assets/media/gallery/photos/80.png';
import P81 from '../assets/media/gallery/photos/81.png';

export const photos = [
  {
    src: P81,
    caption: 'Feast of St. Mary',
    year: '2024'
  },
  {
    src: P80,
    caption: 'Sunday School Unit Level Talent Day',
    year: '2024'
  },
  {
    src: P79,
    caption: 'Holy Week - Fr. Varghese P. A. (Vicar)',
    year: '2024'
  },
  {
    src: P78,
    caption: 'Sunday School Annual Day',
    year: '2024'
  },
  {
    src: P77,
    caption: 'Mayaltho Perunal and Launch of Talmido',
    year: '2024'
  },
  {
    src: P76,
    caption: 'Danaha Perunal',
    year: '2024'
  },
  {
    src: P75,
    caption: 'Ecumenical Christmas Carols',
    year: '2024'
  },
  {
    src: P74,
    caption: 'Christmas',
    year: '2023'
  },
  {
    src: P70,
    caption: 'MMVS Annual Conference',
    year: '2023'
  },
  {
    src: P72,
    caption: 'OCYM North Zone Meet Up',
    year: '2023'
  },
  {
    src: P71,
    caption: 'MMVS Retreat',
    year: '2023'
  },
  {
    src: P69,
    caption: 'OVBS Final Day and Onam Celebrations',
    year: '2023'
  },
  {
    src: P68,
    caption: 'OVBS Final Day Celebrations',
    year: '2023'
  },
  {
    src: P67,
    caption: 'Onam Celebrations',
    year: '2023'
  },
  {
    src: P66,
    caption: 'Onam Celebrations',
    year: '2023'
  },
  {
    src: P65,
    caption: 'Onam Celebrations',
    year: '2023'
  },
  {
    src: P64,
    caption: 'OVBS',
    year: '2023'
  },
  {
    src: P63,
    caption: 'OVBS',
    year: '2023'
  },
  {
    src: P62,
    caption: 'OVBS',
    year: '2023'
  },
  {
    src: P61,
    caption: 'Car Wash Charity Fundraiser by Youth',
    year: '2023'
  },
  {
    src: P60,
    caption: 'Car Wash Charity Fundraiser by Youth',
    year: '2023'
  },
  {
    src: P59,
    caption: 'Car Wash Charity Fundraiser by Youth',
    year: '2023'
  },
  {
    src: P58,
    caption: 'Sunday School Sports Day',
    year: '2023'
  },
    {
      src: P57,
      caption: 'Food Festival by MMVS',
      year: '2023'
    },
    {
      src: P56,
      caption: 'Food Festival by MMVS',
      year: '2023'
    },
    {
      src: P55,
      caption: 'Zonal Level Sunday School Talent Competition',
      year: '2023'
    },
    {
      src: P54,
      caption: 'Zonal Level Sunday School Talent Competition',
      year: '2023'
    },
    {
      src: P53,
      caption: 'MMVS Conference - UK North Zone',
      year: '2023'
    },
    {
      src: P52,
      caption: 'Unit Level Sunday School Competition',
      year: '2023'
    },
    {
      src: P51,
      caption: 'Unit Level Sunday School Competition',
      year: '2023'
    },
    {
      src: P50,
      caption: 'Unit Level Sunday School Competition',
      year: '2023'
    },
    {
      src: P47,
      caption: 'Easter - Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P46,
      caption: 'Easter - Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P44,
      caption: 'Good Friday - Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P45,
      caption: 'Good Friday - Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P43,
      caption: 'MMVS Retreat - Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P42,
      caption: 'Palm Sunday - Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P41,
      caption: 'MMVS led by Rev. Fr. Shyju P Mathai',
      year: '2023'
    },
    {
      src: P48,
      caption: 'Christmas - Rev. Fr. Happy Jacob',
      year: '2022'
    },
    {
      src: P32,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P33,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P34,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P35,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P36,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P37,
      caption: 'H.G. Abraham Mar Stephanos inagurating our website',
      year: '2022'
    },
    {
      src: P38,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P39,
      caption: 'H.G. Abraham Mar Stephanos visit to SMIOC Sunderland',
      year: '2022'
    },
    {
      src: P40,
      caption: 'H.G. Abraham Mar Stephanos with Committee',
      year: '2022'
    },
    {
      src: P21,
      caption: 'Holy Qurbana - Rev. Fr. Happy Jacob',
      year: '2022'
    },
    { 
        src: P30, 
        caption: 'Trip to Wales', 
        year: '2022' 
    },
    { 
        src: P23, 
        caption: 'OVBS', 
        year: '2022' 
    },
    { 
        src: P22, 
        caption: 'OVBS', 
        year: '2022' 
    },
    { 
        src: P20, 
        caption: 'OVBS', 
        year: '2022' 
    },
    { 
        src: P19, 
        caption: 'OVBS', 
        year: '2022' 
    },
    {
      src: P31,
      caption: 'Good Friday - Rev. Fr. A. M. Mathai CorEpiscopa',
      year: '2022'
    },
    { 
        src: P17, 
        caption: 'Onam', 
        year: '2021' 
    },
    { 
        src: P9, 
        caption: 'Onam', 
        year: '2021' 
    },
    { 
        src: P7, 
        caption: 'Trip to Kent', 
        year: '2021' 
    },
    { 
        src: P12, 
        caption: 'OVBS', 
        year: '2021' 
    },
    { 
        src: P1, 
        caption: 'OVBS', 
        year: '2021' 
    },
    { 
        src: P28, 
        caption: 'Christmas', 
        year: '2019' 
    },
    { 
        src: P8, 
        caption: 'Christmas Carols', year: '2019' },
    {
      src: P25,
      caption: 'Volunteering at Sunderland Food Bank',
      year: '2019'
    },
    { 
        src: P29, 
        caption: 'Onam', 
        year: '2019' 
    },
    {
      src: P27,
      caption: 'Easter- Rev. Fr. Jobin Cherian',
      year: '2019'
    },
    {
      src: P6,
      caption: 'Good Friday - Rev. Fr. Jobin Cherian',
      year: '2019'
    },
    {
      src: P5,
      caption: 'Good Friday - Rev. Fr. Jobin Cherian',
      year: '2019'
    },
    { 
        src: P15, 
        caption: 'OVBS', 
        year: '2018' 
    },
    { 
        src: P14, 
        caption: 'OVBS', 
        year: '2018' 
    },
    { 
        src: P24, 
        caption: 'Onam', 
        year: '2017' 
    },
    { 
        src: P18, 
        caption: 'Sunday School', 
        year: '2017' 
    },
    { 
        src: P13, 
        caption: 'Prayer Meeting', 
        year: '2016' 
    }
  ]

