import axios from "axios";
import { useEffect, useState, useRef } from "react";

const BibleVerse = () => {
    
    const [verse, setVerse] = useState("Give thanks to the Lord, for he is good; his love endures forever.");
    const [reference, setReference] = useState(<><br />1 Chronicles 16:34</>)

    const chapter =  useRef(Math.floor(Math.random() * (150 - 1 + 1)) + 1);
    const verseNumber =  useRef(Math.floor(Math.random() * (20 - 1 + 1)) + 1);

    const baseUrl = "https://bible-api.com/";
    const url =  baseUrl+"psalm+"+chapter.current+":"+verseNumber.current+"?verse_numbers=true?translation=kjv";

    useEffect(() => {
        const getVerse = () => {
            axios.get(url)
            .then((response) => {
                setVerse(response.data.text)
                setReference(response.data.reference)
            })
            .catch(error => 
                console.log(error),
            );
        };
        getVerse()
    },);

    return (
        <i>{verse}{reference}</i>
    )
}

export default BibleVerse;