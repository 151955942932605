import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../Footer/footer.css';
import DS from '../../assets/logos/DS.png';

import { BiCopyright } from 'react-icons/bi';

var year = new Date().getFullYear();

const Footer = () => {
    return (
        <Container fluid className="footer">
            <Row>
                <Col className="footer-icons">
                    <BiCopyright size="1em"/> ST. MARY'S INDIAN ORTHODOX CHURCH, SUNDERLAND | {year} |
                </Col>
            </Row>
            <Row>
                <div className="footer-inline">
                    <a className='footer-links' href={"/termsandconditions"}>Terms and Conditions</a>
                    <a className='footer-links' href={"/privacypolicy"}>Privacy Policy</a>
                </div>
            </Row>
            <Row>
                <Col className="footer-icons">
                    <img src={DS} alt="MOSC Logo" className="ds-logo"/>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;