import { useTitle } from 'react-use';
import Text from '../components/Text/Text';
import Subtitle from '../components/Subtitle/Subtitle';
import Title from '../components/Title/Title';
import { Container } from 'react-bootstrap';

const TermsAndConditions = () => {
    useTitle('SMIOC - Terms and Conditions');
    
    return (
        <Container>
            <Title title="Terms and Conditions" />

            <Text description="Welcome to St. Mary's Indian Orthodox Church, Sunderland, UK. 
            The terms and conditions below outline the rules and regulations for the use of 
            the website, St. Mary's Indian Orthodox Church, Sunderland. By using our website, 
            you hereby consent to our Terms and Conditions outlined by St. Mary's Indian
            Orthodox Church, Sunderland, UK along with our privacy policy." />

            <Text description="The term “St. Mary's Indian Orthodox Church, Sunderland or 
            ”SMIOC Sunderland” or “us” or “we” or “our” refers to the owner of the website. 
            The term “you” refers to the visitor or viewer of our website. If you have additional 
            questions or require more information about our Terms and Conditions or Privacy Policy, 
            please do not hesitate to contact us." />

            <Subtitle className="subtitle-left" subtitle={"Legal Information"} />

            <Text description="The use of this website is subject to the following terms: " />

            <ul>
                <li>
                    SMIOC Sunderland has taken care in the preparation of the content of this 
                    website. The information presented is believed to be reliable but is subject 
                    to change at any time without notice. 
                </li>
                <li>
                    SMIOC Sunderland shall not be liable for any loss which arise from the 
                    use of the information.
                </li>
                <li>
                    Certain links in this site will lead you to websites which are not under 
                    the control of SMIOC Sunderland. When you activate these links, you will 
                    leave the SMIOC Sunderland website where SMIOC Sunderland will accept no 
                    responsibility or liability in respect of the material on any other website.
                </li>
                <li>
                    Images on our website are protected by copyright.
                </li>
            </ul>
            
            <Subtitle className="subtitle-left" subtitle={"License"} />

            <Text description="The copyright in the contents contained in this website belongs 
            to SMIOC Sunderland. You may use the information on the SMIOC Sunderland website 
            for your own personal use subjected to restrictions set in these terms and conditions." />

            <Text description="You must not:" />

            <ul>
                <li>
                    Republish, redistribute, sell, or sub-license any material from SMIOC Sunderland.
                </li>
                <li>
                    Reproduce, duplicate or copy matieral from SMIOC Sunderland.
                </li>
                <li>
                    Images on our website may not be reproduced or appropriated in any manner 
                    without written permisson of SMIOC Sunderland.
                </li>
            </ul>

            <Subtitle className="subtitle-left" subtitle={"Contact Us"} />

            <Text description={<>If you have additional questions or concerns you would like to 
            raise about our Terms and Conditions, please do not hesitate to contact us. This 
            should be addressed directly to <a href = "mailto:secretary@stmarysiocsunderland.org">secretary@stmarysiocsunderland.org</a></>} />

            <br />

        </Container>
        
    )
}

export default TermsAndConditions;