import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const GoogleMaps = ({ center }) => {
    const containerStyle = {
        width: '100%',
        height: '30rem',
    };
   
    return (
        <LoadScript googleMapsApiKey="AIzaSyBV-on8n11MC6uOG6fv8VHDDHJm2NMcx44">
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
                <MarkerF position={center}></MarkerF>
            </GoogleMap>
        </LoadScript>
    )
}

export default GoogleMaps;