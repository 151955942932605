import { Container } from 'react-bootstrap';
import '../Text/text.css'

const Text = (props) => {
    return (
        <Container className="text">
            <p className={props.className}>{props.icon}{props.description}</p>
        </Container>
    )
}

export default Text;