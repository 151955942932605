import './App.css';
import Navigation from './components/Navigation/Navigation';
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Gallery from './pages/Gallery';
import Events from './pages/Events';
import OfficeBearers from './pages/OfficeBearers';
import ContactUs from './pages/ContactUs';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/PageNotFound';
import Footer from './components/Footer/Footer';
import Prefooter from './components/Prefooter/Prefooter';

function App() {
  return (
    <div className="App">
        <Navigation />
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/" exact element={<Home />} />
            <Route path="/aboutus" exact element={<AboutUs />} />
            <Route path="/gallery" exact element={<Gallery />} />
            <Route path="/events" exact element={<Events />} />
            <Route path="/officebearers" exact element={<OfficeBearers />} />
            <Route path="/contactus" exact element={<ContactUs />} />
            <Route path="/termsandconditions" exact element={<TermsAndConditions />} />
            <Route path="/privacypolicy" exact element={<PrivacyPolicy />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to ="/404" />}/>
          </Routes>
        </BrowserRouter>
        <Prefooter />
        <Footer />
    </div>

  );
}

export default App;
